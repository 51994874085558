<template>
  <div class="content">
    <h2 class="text-center mb-4">Add Product</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <TextField
          id="productTitle"
          v-model="currentProduct.title"
          :errorMessage="
            handlerValidate?.['title']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productTitle"
          :label="'Nombre del Producto'"
        />
      </div>

      <div class="form-group">
        <label for="productDescription">Descripción del Producto</label>
        <textarea
          class="form-control"
          id="productDescription"
          v-model="currentProduct.description"
          rows="3"
        ></textarea>
        <p
          v-if="handlerValidate?.['description']?.$errors?.length > 0"
          v-text="'Este campo es requerido'"
        />
      </div>
      <div class="form-group">
        <TextField
          id="productPrice"
          v-model="currentProduct.price"
          :errorMessage="
            handlerValidate?.['price']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
          name="productPrice"
          :label="'Precio del Producto (USD)'"
        />
        <!-- <label for="productPrice">Product Price</label>
          <input
            type="number"
            class="form-control"
            id="productPrice"
            v-model="currentProduct.price"
            required
          /> -->
      </div>
      <div class="form-group ">
        <label class="mb-2" for="productCategory">Categoría del Producto</label>
        <div class="row gap-3 check-form">
          <CheckBoxInput
            class="gap-1  col-2 edit-p"
            v-for="(category, index) in categories"
            :key="category.id"
            :value="category.id"
            :for="`${index}`"
            :text="category.name"
            :checked="isInCurrentProduct(category.id)"
            @change="checkOptionUpdate(category.id)"
          />
        </div>
      </div>

      <div class="form-group pt-4 pb-2">
        <label class="style-check font-medium text-tertiary">
          <input type="checkbox" v-model="susc" />
          <span class="checkmark checkmark__blue" />
          <span v-text="'Producto de  suscripción '" />
        </label>
      </div>

      <div class="form-group">
        <label for="url">Url del curso</label>
        <input
          type="url"
          name="url"
          class="form-control"
          v-model="currentProduct.external_url"
          id="url"
          placeholder="https://example.com"
          pattern="https://.*"
 
        />

        <div class="textField">
          <p
            v-if="showError"
            class="error-message"
            v-text="'Por favor, ingresa una URL válida.'"
          />
       
        </div>
      </div>

      <!-- <div class="form-group">
        <label for="productCategory">Product Category</label>
        <select
          class="form-control"
          id="productCategory"
          v-model="currentProduct.category"
          required
        >
          <option
            v-for="category in categories"
            :key="category.id"
            :value="category.id"
          >
            {{ category.name }}
          </option>
        </select>
      </div> -->
      <!-- Portrait Image Upload -->
      <div class="form-group">
        <label class="mb-2" for="productPortrait">Imagen del producto</label
        ><br />
        <img class="img-styles" v-if="currentProduct.image" :src="currentProduct.image"/>

        <input
          type="file"
          class="form-control-file"
          id="productPortrait"
          @change="uploadPortraitImage($event)"
          accept="image/*"
        />
      </div>

      <div id="modulesContainer">
        <h4 class="mt-4">Módulos</h4>
        <div
          class="card"
          v-for="(module, moduleIndex) in currentProduct.modules"
          :key="moduleIndex"
          :id="'module' + (moduleIndex + 1)"
        >
          <div class="card-body">
            <h5 class="card-title">Module {{ moduleIndex + 1 }}</h5>
            <div class="form-group">
              <label :for="'moduleTitle' + (moduleIndex + 1)"
                >Module Title</label
              >
              <input
                type="text"
                class="form-control"
                :id="'moduleTitle' + (moduleIndex + 1)"
                v-model="module.title"
                required
              />
            </div>
            <div :id="'lessonsContainer' + (moduleIndex + 1)">
              <h6>Lecciones</h6>
              <div
                class="card mb-4"
                v-for="(lesson, lessonIndex) in module.lessons"
                :key="lessonIndex"
                :id="'lesson' + (moduleIndex + 1) + '_' + (lessonIndex + 1)"
              >
                <div class="card-body">
                  <h6 class="card-title">Lesson {{ lessonIndex + 1 }}</h6>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Título de la lección</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :id="
                        'lessonTitle' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.title"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label
                      :for="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Contenido de la lección</label
                    >
                    <textarea
                      class="form-control"
                      :id="
                        'lessonContent' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      v-model="lesson.content"
                      rows="3"
                      required
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label
                      class="mb-2"
                      :for="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      >Upload Video</label
                    ><br />
                    {{ lesson.video_name }}
                    <br />
                    <input
                      type="file"
                      class="form-control-file"
                      :id="
                        'lessonVideo' +
                        (moduleIndex + 1) +
                        '_' +
                        (lessonIndex + 1)
                      "
                      @change="uploadVideo($event, moduleIndex, lessonIndex)"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="removeLesson(moduleIndex, lessonIndex)"
                  >
                    Eliminar lección
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-secondary mt-2 mx-2"
              @click="addLesson(moduleIndex)"
            >
              Añadir Lección
            </button>

            <button
              type="button"
              class="btn btn-danger mt-2"
              @click="removeModule(moduleIndex)"
            >
              Remove Module
            </button>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-4 mx-2"
        @click="addModule"
      >
        Agregar Modulo
      </button>
      <button type="submit" class="btn btn-primary mt-4">Subir</button>
    </form>
  </div>
</template>

<script>
import TextField from "@/components/common/TextField.vue"
import { useProductStore } from "@/stores/productStore"
// import { useAuth0 } from "@auth0/auth0-vue"
import CheckBoxInput from "@/components/common/CheckInput.vue"
import { showNotification } from "@/composables/useNotification"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { onMounted, ref } from "vue"

export default {
  name: "ProductForm",
  components: {
    TextField,
    CheckBoxInput,
  },
  setup() {
    const store = useProductStore();
    // const { getAccessTokenSilently } = useAuth0();
    const currentProduct = ref(store.currentProduct);
    const categories = ref([]);
    const susc = ref(false);
    const showError = ref(false);
    const handlerValidate = useVuelidate(
      {
        title: {
          required,
        },
        description: {
          required,
        },
        price: {
          required,
        },
    
      },
      currentProduct.value
    );
    const isInCurrentProduct = (id) => {
      const index = currentProduct.value.categories.findIndex(item => item === id)
      if (index > -1) {
        return true
      }
      return false
    }

    const fetchAndSetCategories = async () => {
      await store.fetchCategories();
      categories.value = store.categories;
      susc.value = store.currentProduct.is_sub
    };

    const checkOptionUpdate = (event) => {
      const index = currentProduct.value.categories.indexOf(event);
      if (index !== -1) {
        currentProduct.value.categories.splice(index, 1);
      } else {
        currentProduct.value.categories.push(event);
      }
    };

    const addModule = () => store.addModule();
    const removeModule = (moduleIndex) => store.removeModule(moduleIndex);
    const addLesson = (moduleIndex) => store.addLesson(moduleIndex);
    const removeLesson = (moduleIndex, lessonIndex) =>
      store.removeLesson(moduleIndex, lessonIndex);
    const uploadPortraitImage = (event) => {
      const file = event.target.files[0];
      currentProduct.value.portraitImage = file;
    };

    const uploadVideo = async (event, moduleIndex, lessonIndex) => {
      const file = event.target.files[0];
      currentProduct.value.modules[moduleIndex].lessons[lessonIndex].videoFile =
        file;
    };
    // const validateInput = (event) => {
    //   let value = event.target.value;
    //   value = value.replace(/[^0-9,.]/g, "");
      
    //   if (value.includes(",")) {
    //     value = value.replace(",", ".");
    //   }
    //   const parts = value.split(".");
    //   if (parts.length > 2) {
    //     value =
    //       parts[0] +
    //       "." +
    //       parts
    //         .slice(1, 3)
    //         .join("")
    //         .replace(/[^0-9]/g, "");
    //   } else if (parts.length === 2) {
    //     //error máximo dos decimales
    //     value = parts[0] + "." + parts[1].slice(0, 2).replace(/[^0-9]/g, "");
    //   }

    //   currentProduct.value.price = value;
    // };
    const submitForm = async () => {
      const _validate = await handlerValidate.value.$validate();
      
      if (!_validate) return;

      const formData = new FormData();
      formData.append("title", currentProduct.value.title);
      formData.append("description", currentProduct.value.description);
      formData.append("price", currentProduct.value.price);
      formData.append("categories", JSON.stringify(currentProduct.value.categories));
      formData.append("is_sub", susc.value);
      formData.append("external_url", currentProduct.value.external_url ? currentProduct.value.external_url : "");

      if (currentProduct.value.portraitImage) {
        formData.append("portraitImage", currentProduct.value.portraitImage);
      }

      const modulesData = currentProduct.value.modules.map((module) => ({
        title: module.title,
        id: module.id,
        lessons: module.lessons.map((lesson) => ({
          title: lesson.title,
          content: lesson.content,
          id: lesson.id
        })),
      }));

      formData.append("modules", JSON.stringify(modulesData));

      currentProduct.value.modules.forEach((module) => {
        module.lessons.forEach((lesson) => {
          if (lesson.videoFile) {
            formData.append(
              `file_${module.title}_${lesson.title}`,
              lesson.videoFile
            );
          }
        });
      });

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      try {
        const response = await store.editProduct(currentProduct.value.id, formData)

        if (response.status === 200) {
          showNotification("Producto editado correctamente", "success");
        }
      } catch (error) {
        showNotification("Algo ha ido mal", "error");
        console.error("Updating error:", error);
      }
    };

    onMounted(() => {
      fetchAndSetCategories();
    });

    return {
      currentProduct,
      categories,
      addModule,
      removeModule,
      addLesson,
      removeLesson,
      uploadVideo,
      submitForm,
      uploadPortraitImage,
      handlerValidate,
      checkOptionUpdate,
      isInCurrentProduct,
      susc,
      showError,
      // validateInput,
    };
  },
};
</script>

<style scoped>
.content {
  margin-left: 240px;
  padding: 20px;
}
.form-group {
  margin-bottom: 20px;
}
label {
  font-weight: 500;
}
.img-styles {
  width: 150px;
    border-radius: 4px;
    margin-right: 20px;
}
.edit-p .style-check .checkmark {
    top: 25px!important;
}
</style>
