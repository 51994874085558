<template>
  <div class="main-title">
    <h2>{{ $t("PROFILE.TITLE") }}</h2>
    <button
      v-if="!edit"
      @click="edit = !edit"
      class="btn btn-dark"
    >
      <span v-if="!edit"> {{ $t("PROFILE.EDIT") }} </span>
    </button>
    <button
      v-else
      class="btn btn-primary"
      @click="save"
    >
      <span >{{ $t("PROFILE.SAVE") }}</span>
    </button>
  </div>
  <section>
    <div class="bg-sections d-flex flex-column-reverse flex-md-row mb-2">
      <div class="profile">
        <button v-if="!isAuthenticated" @click="login" class="btn btn-primary">
          {{ $t("PROFILE.LOGIN") }}
        </button>
        <div v-else>
          <div class="user-details">
            <img
              :src="user.picture"
              alt="Profile Picture"
              class="profile-picture mb-3"
            />
            <h3>{{ user.name }}</h3>
            <p>{{ user.email }}</p>
          </div>
          <button @click="logOut" class="btn mb-3 btn-secondary">
            {{ $t("PROFILE.LOGOUT") }}
          </button>
        </div>
      </div>
      <div class="mx-4 w-full">
        <p class="text-secondary" v-text="$t('PROFILE.INFO')"></p>
        <span class="">Contactenos si desea cambiar esta informacion</span>
        <div class="d-flex flex-column flex-md-row gap-4 w-50">
          <ProfileForm :form-data="{
            given_name: user.given_name,
            family_name: user.family_name,
            email: user.email,
            phone: producerStore?.profile?.user?.phone
          }" :edit="edit" />
        </div>
      </div>
    </div>

    <div class="w-full">
      <CompanyForm :edit="edit"/>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UserProfile',
}
</script>

<script setup>
import CompanyForm from "@/components/forms/CompanyForm.vue"
import ProfileForm from "@/components/forms/ProfileForm.vue"
import useProducerStore from "@/stores/Producer"

import { useAuth0 } from "@auth0/auth0-vue"
import { onMounted, ref } from "vue"

const producerStore = useProducerStore();

const {
  loginWithRedirect,
  // getAccessTokenSilently,
  logout,
  user,
  isAuthenticated,
} = useAuth0();
const edit = ref(false);

const login = () => {
  loginWithRedirect();
}

const logOut = () => {
  logout({ returnTo: window.location.origin });
}

// const checka = async () => {
//   await getAccessTokenSilently();
//   console.log(user);
// }

onMounted(async () => {
  // await checka();
  await producerStore.getUserProfile(user.value.sub)
  await producerStore.getProducer(user.value.sub)
})

const save = async () => {
  edit.value = false
  await producerStore.saveProfile({
    company_name: producerStore.producer?.company_name,
    entity_type: producerStore.producer?.entity_type,
    registration_number: producerStore.producer?.registration_number,
    tax_id_number: producerStore.producer?.tax_id_number,
    vat_id_number: producerStore.producer?.vat_id_number,

    city: producerStore.profile.city,
    address: producerStore.profile.address,
    country: producerStore.profile.country,
    state: producerStore.profile.state,
    zip_code: producerStore.profile.zip_code,
    apartment: producerStore.profile.apartment,
    payload_email: producerStore.profile.payload_email,
    account_type: producerStore.profile.account_type,


    request_type: 'producer', // solo por que en backend es necesario para otro proceso, pero no tiene nada que ver aqui
  })
}


</script>
