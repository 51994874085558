<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useSubscriptionsStore } from "@/stores/SubscriptionsStore";
import { useProductStore } from "@/stores/productStore";
import DataTable from "@/components/common/DataTable.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { Modal } from "bootstrap";

const { getAccessTokenSilently, user } = useAuth0();
const subscriptionsStore = useSubscriptionsStore();
const productStore = useProductStore();

const selectedProductId = ref("");
const isLoading = ref(true);
const nameFilter = ref("");
const lastNameFilter = ref("");
const statusFilter = ref("");
const subscriptionToCancel = ref(null);
let cancelModal = null;

onMounted(async () => {
  isLoading.value = true;
  const token = await getAccessTokenSilently({
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  });
  const userId = user.value.sub;
  localStorage.setItem("token", token);
  await productStore.getAllProducts(userId);
  isLoading.value = false;
  cancelModal = new Modal(document.getElementById("cancelSubscriptionModal"));
});

watch(selectedProductId, (newValue) => {
  if (newValue) {
    loadSubscriptions();
  } else {
    subscriptionsStore.getSubscriptions({ productId: null });
  }
});

const loadSubscriptions = async () => {
  if (selectedProductId.value) {
    await subscriptionsStore.getSubscriptions({
      productId: selectedProductId.value,
      first_name: nameFilter.value,
      lastName: lastNameFilter.value,
      status: statusFilter.value,
      page: 1,
    });
  }
};

const subscriptionProducts = computed(() =>
  (productStore.listProducts.products || []).filter((product) => product.is_sub)
);

const hasSubscriptionProducts = computed(
  () => subscriptionProducts.value.length > 0
);

const titlesTable = computed(() => [
  { width: "14.28%", title: "Nombre" },
  { width: "14.28%", title: "Apellido" },
  { width: "14.28%", title: "Total" },
  { width: "14.28%", title: "Siguiente Pago" },
  { width: "14.28%", title: "Último Pago" },
  { width: "14.28%", title: "Estado" },
  { width: "14.28%", title: "Tarjeta" },
  { width: "12.5%", title: "Cancelar" },
]);

const formatDate = (dateString) => {
  if (!dateString) return "-";
  return dateString; // The date is already in yyyy-mm-dd format
};

const formatTotal = (total) => {
  if (total === "-") return total;
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(total);
};

const getStatusClass = (status) => {
  switch (status.toLowerCase()) {
    case "active":
      return "badge bg-success";
    case "completed":
      return "badge bg-primary";
    case "issue":
      return "badge bg-warning";
    case "archived":
      return "badge bg-secondary";
    default:
      return "badge bg-secondary";
  }
};

const changePage = (page) => {
  if (selectedProductId.value) {
    subscriptionsStore.getSubscriptions({
      productId: selectedProductId.value,
      name: nameFilter.value,
      lastName: lastNameFilter.value,
      status: statusFilter.value,
      page: page,
    });
  }
};
const handleCancelSubscription = (subscriptionId) => {
  subscriptionToCancel.value = subscriptionId;
  cancelModal.show();
};

const confirmCancelSubscription = async () => {
  try {
    await subscriptionsStore.cancelSubscription(subscriptionToCancel.value);
    cancelModal.hide();
    await loadSubscriptions();
  } catch (error) {
    console.error("Error al cancelar la suscripción:", error);
    // You might want to show an error message to the user here
  }
};

const paginationRange = computed(() => {
  const currentPage = subscriptionsStore.currentPage;
  const pageCount = subscriptionsStore.pageCount;
  const maxVisiblePages = 5;

  let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(pageCount, startPage + maxVisiblePages - 1);

  if (endPage - startPage + 1 < maxVisiblePages) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  return {
    startPage,
    endPage,
    pageCount,
  };
});

const showPagination = computed(() => subscriptionsStore.pageCount > 1);
</script>

<template>
  <section>
    <h2 class="h2 mb-4">Suscripciones</h2>

    <div v-if="isLoading" class="text-center py-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <template v-else>
      <div class="mb-4">
        <div class="row g-3">
          <div class="col-md-3">
            <select
              id="product-select"
              v-model="selectedProductId"
              class="form-select"
            >
              <option value="">Selecciona un Producto</option>
              <option
                v-for="product in subscriptionProducts"
                :key="product.id"
                :value="product.id"
              >
                {{ product.title }}
              </option>
            </select>
          </div>
          <div class="col-md-2">
            <input
              type="text"
              v-model="nameFilter"
              placeholder="Filtrar por nombre"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <input
              type="text"
              v-model="lastNameFilter"
              placeholder="Filtrar por apellido"
              class="form-control"
            />
          </div>
          <div class="col-md-2">
            <select v-model="statusFilter" class="form-select">
              <option value="">Estado</option>
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="issue">Issue</option>
              <option value="archived">Archived</option>
            </select>
          </div>
          <div class="col-md-3">
            <button
              @click="loadSubscriptions"
              class="search-button"
              :disabled="!selectedProductId"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="!hasSubscriptionProducts"
        class="alert alert-info mb-4 text-center py-4"
      >
        No tienes productos de suscripción
      </div>

      <div v-else-if="!selectedProductId" class="mb-4 text-center py-4">
        Por favor, selecciona un producto para ver las suscripciones
      </div>

      <div v-else-if="subscriptionsStore.isLoading" class="text-center py-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando suscripciones...</span>
        </div>
      </div>

      <div
        v-else-if="subscriptionsStore.isError"
        class="alert alert-danger text-center py-4"
      >
        Error al cargar suscripciones: {{ subscriptionsStore.error }}
      </div>

      <template v-else>
        <div class="table-responsive">
          <DataTable
          :noHaveData="!subscriptionsStore.subscriptions.length"
          :headers="titlesTable"
        >
          <template #body>
            <tr
              v-for="subscription in subscriptionsStore.subscriptions"
              :key="subscription.id"
            >
              <td>{{ subscription.firstName }}</td>
              <td>{{ subscription.lastName }}</td>
              <td>{{ formatTotal(subscription.total) }}</td>
              <td>{{ formatDate(subscription.payment.next) }}</td>
              <td>{{ formatDate(subscription.payment.last) }}</td>
              <td>
                <span :class="getStatusClass(subscription.status)">
                  {{ subscription.status }}
                </span>
              </td>
              <td>**** **** **** {{ subscription.cardNumber }}</td>
              <td>
                <button
                  @click="handleCancelSubscription(subscription.id)"
                  class="btn btn-danger btn-sm"
                  :disabled="
                    subscription.status.toLowerCase() !== 'active' ||
                    subscriptionsStore.isLoading
                  "
                >
                  <span
                    v-if="subscriptionsStore.isLoading"
                    class="spinner-border spinner-border-sm me-1"
                    role="status"
                  >
                    <span class="visually-hidden">Cargando...</span>
                  </span>
                  Cancelar<br/> suscripción
                </button>
              </td>
            </tr>
          </template>
        </DataTable>
        </div>

        <nav v-if="showPagination" aria-label="Page navigation" class="mt-4">
          <ul class="pagination justify-content-center">
            <li
              class="page-item"
              :class="{ disabled: subscriptionsStore.currentPage === 1 }"
            >
              <button
                class="page-link text-black"
                @click="changePage(subscriptionsStore.currentPage - 1)"
                :disabled="subscriptionsStore.currentPage === 1"
              >
                Anterior
              </button>
            </li>
            <template v-if="paginationRange.startPage > 1">
              <li class="page-item">
                <button class="page-link text-black" @click="changePage(1)">
                  1
                </button>
              </li>
              <li
                v-if="paginationRange.startPage > 2"
                class="page-item disabled"
              >
                <span class="page-link text-black">...</span>
              </li>
            </template>
            <li
              v-for="page in paginationRange.endPage -
              paginationRange.startPage +
              1"
              :key="page + paginationRange.startPage - 1"
              class="page-item"
              :class="{
                active:
                  subscriptionsStore.currentPage ===
                  page + paginationRange.startPage - 1,
              }"
            >
              <button
                class="page-link"
                :class="{
                  'bg-dark text-white':
                    subscriptionsStore.currentPage ===
                    page + paginationRange.startPage - 1,
                  'text-black':
                    subscriptionsStore.currentPage !==
                    page + paginationRange.startPage - 1,
                }"
                @click="changePage(page + paginationRange.startPage - 1)"
              >
                {{ page + paginationRange.startPage - 1 }}
              </button>
            </li>
            <template
              v-if="paginationRange.endPage < paginationRange.pageCount"
            >
              <li
                v-if="paginationRange.endPage < paginationRange.pageCount - 1"
                class="page-item disabled"
              >
                <span class="page-link text-black">...</span>
              </li>
              <li class="page-item">
                <button
                  class="page-link text-black"
                  @click="changePage(paginationRange.pageCount)"
                >
                  {{ paginationRange.pageCount }}
                </button>
              </li>
            </template>
            <li
              class="page-item"
              :class="{
                disabled:
                  subscriptionsStore.currentPage === paginationRange.pageCount,
              }"
            >
              <button
                class="page-link text-black"
                @click="changePage(subscriptionsStore.currentPage + 1)"
                :disabled="
                  subscriptionsStore.currentPage === paginationRange.pageCount
                "
              >
                Siguiente
              </button>
            </li>
          </ul>
        </nav>
      </template>
    </template>
    <div
      class="modal fade"
      id="cancelSubscriptionModal"
      tabindex="5"
      aria-labelledby="cancelSubscriptionModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cancelSubscriptionModalLabel">
              ¿Cancelar suscripción?
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            ¿Estás seguro de que deseas cancelar esta suscripción?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="cerrar-btn btn"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="confirmCancelSubscription"
              :disabled="subscriptionsStore.isLoading"
            >
              <span
                v-if="subscriptionsStore.isLoading"
                class="spinner-border spinner-border-sm me-1"
                role="status"
              >
                <span class="visually-hidden">Cargando...</span>
              </span>
              Cancelar suscripción
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.page-link {
  color: #000;
}
.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.modal-footer{
  border: none;
}
.cerrar-btn{
  background-color:white;
  color: black;
  
}
.cerrar-btn:hover{
  background-color: rgb(212, 212, 212);
  color: black;
}
.search-button{
  background-color: #212429;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px
}
.table-responsive {
  overflow-x: auto;
}
</style>
