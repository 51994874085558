<script setup>
import CheckBoxInput from "@/components/common/CheckInput.vue";
import TextField from "@/components/common/TextField.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { ref } from "vue";
import useStoreFrom from "@/stores/Form.js";
import { showNotification } from "@/composables/useNotification";
import useProducerStore from "@/stores/Producer"


const producerStore = useProducerStore()
const storeForm = useStoreFrom();
const selectedStructure = ref([]);
const selectedTools = ref([]);
const dataForm = ref({});
const handlerValidate = useVuelidate(
  // {
  //   saleCourse: {
  //     required,
  //   },
  //   typeCourse: {
  //     required,
  //   },
  //   record: {
  //     required,
  //   },
  //   publicity: {
  //     required,
  //   },
  //   contentRss: {
  //     required,
  //   },
  //   needHelp: {
  //     required,
  //   },
  // },
  {
    ha_vendido_cursos: {
      required,
    },
    tipo_cursos_vendidos: {
      required,
    },
    grabas_tus_cursos: {
      required,
    },
    campanas_publicidad: {
      required,
    },
    generas_contenido_redes: {
      required,
    },
    apoyo_setters_closers: {
      required,
    },
    ayuda_estructuracion_guion: {
      required: false,
    },
    ayuda_estructuracion_grabado: {
      required: false,
    },
    ayuda_estructuracion_edicion: {
      required: false,
    },
    ayuda_estrategia_marketing: {
      required: false,
    },
    ayuda_manejo_trafico: {
      required: false,
    },
    ayuda_elaboracion_ads: {
      required: false,
    },
    ayuda_elaboracion_video: {
      required: false,
    },
    ayuda_edicion_videos: {
      required: false,
    },
    ayuda_equipo_ventas: {
      required: false,
    },
    ayuda_otra_cosa: {
      required: false,
    },
  },
  dataForm.value
);

const structure = ref([
  {
    id: 1,
    name: "Necesitas ayuda con el guion",
  },
  {
    id: 2,
    name: "Necesitas ayuda con el grabado",
  },
  {
    id: 3,
    name: "Necesitas ayuda con la edición",
  },
]);

const tools = ref([
  {
    id: 1,
    name: "Estrategia de Marketing",
  },
  {
    id: 2,
    name: "Manejo de Tráfico",
  },
  {
    id: 3,
    name: "Elaboración de Ads",
  },
  {
    id: 4,
    name: "Elaboración de Video",
  },
  {
    id: 5,
    name: "Edición de Videos",
  },
  {
    id: 6,
    name: "Equipo de Ventas (Closers y Setters)",
  },
  {
    id: 7,
    name: "⁠Otra cosa",
  },
]);

const handleChangeEstructure = (value) => {
  if (selectedStructure.value.includes(value)) {
    selectedStructure.value = selectedStructure.value.filter(
      (item) => item !== value
    );
  } else {
    selectedStructure.value.push(value);
  }
};

const handleChangeTools = (value) => {
  if (selectedTools.value.includes(value)) {
    selectedTools.value = selectedTools.value.filter((item) => item !== value);
  } else {
    selectedTools.value.push(value);
  }
};
const sendForm = async () => {
  const _validate = await handlerValidate.value.$validate();
  if (!_validate) return;

  dataForm.value['producer'] = producerStore.producer.id

  await storeForm.sendCuestionary(dataForm.value);

  if (storeForm.isReady) {
    showNotification("Cuestionario enviado", "success");
  }

  if (storeForm.isError) {
    showNotification("Cuestionario no enviado", "error");
  }
};
</script>
<template>
  <section>
    <p class="title-text fw-bold" v-text="'Cuestionario de asesoramiento:'" />
    <p
      class="max-w-md mb-4"
      v-text="
        'En Skilpering queremos ser tus aliados, para lo cual tenemos un abanico de opciones para ayudarte en tu labor como Skilper. Para esto, necesitamos que llenes el siguiente formulario, que nos permitirá entender cuáles son tus necesidades.'
      "
    />

    <div class="form-group w-30">
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="dataForm.ha_vendido_cursos"
          :label="'¿Has vendido anteriormente cursos?'"
          class="mb-2"
          :errorMessage="
            handlerValidate?.['ha_vendido_cursos']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
        <TextField
          v-model="dataForm.tipo_cursos_vendidos"
          :label="'¿Qué tipo de cursos has vendido?'"
          class="mb-2"
          :errorMessage="
            handlerValidate?.['tipo_cursos_vendidos']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
      </div>
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="dataForm.grabas_tus_cursos"
          :label="'¿Grabas tú mismo tus cursos?'"
          class="mb-2"
          :errorMessage="
            handlerValidate?.['grabas_tus_cursos']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
        <TextField
          v-model="dataForm.campanas_publicidad"
          :label="'¿Haces tus propias campañas de publicidad?'"
          class="mb-2"
          :errorMessage="
            handlerValidate?.['campanas_publicidad']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
      </div>
      <div class="d-md-flex gap-4 mb-2">
        <TextField
          v-model="dataForm.generas_contenido_redes"
          :label="'¿Generas tu propio contenido en redes?'"
          class="mb-2"
          :errorMessage="
            handlerValidate?.['generas_contenido_redes']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
        <TextField
          v-model="dataForm.apoyo_setters_closers"
          :label="'¿Necesitas que te apoyemos con setters o closers?'"
          class="mb-4"
          :errorMessage="
            handlerValidate?.['apoyo_setters_closers']?.$errors?.length > 0
              ? 'Este campo es requerido'
              : undefined
          "
        />
      </div>
      <div class="mb-4">
        <p
          class="textField mb-2"
          v-text="'¿Necesitas ayuda en la estructuración de tu curso?:'"
        ></p>
        <CheckBoxInput
          class="d-md-flex gap-4 mb-2"
          v-for="(item, index) in structure"
          :key="item.id"
          :value="item.id"
          :for="`${index}`"
          :text="item.name"
          @change="handleChangeEstructure(item.id)"
        />
      </div>

      <div class="mb-4">
        <p class="textField mb-2" v-text="'¿Necesitas ayuda con?:'"></p>
        <div>
          <label
            v-for="(tool, indexTool) in tools"
            :key="tool.id"
            class="style-check font-medium text-tertiary d-md-flex gap-4 mb-2"
          >
            <input
              :value="tool.id"
              :for="`${indexTool}`"
              type="checkbox"
              @change="handleChangeTools(tool.id)"
            />
            <span class="checkmark checkmark__blue" />
            <span v-text="tool.name" />
          </label>
        </div>
      </div>
      <button
        class="btn btn-primary w-50 text-center m-auto d-flex justify-content-center"
        @click="sendForm"
        type="button"
        :disabled="storeForm.isLoading"
      >
        Enviar
      </button>
    </div>
  </section>
</template>
<style scoped>
.textField {
  font-weight: 500;
}
.w-30 {
  width: 70%;
}
@media only screen and (max-width: 600px) {
  .w-30 {
    width: 100%;
  }
}
</style>
