<script setup>
import { ref, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { useReportsStore } from "../stores/Report";

const { getAccessTokenSilently } = useAuth0();
const reportsStore = useReportsStore();

const isLoading = ref(false);
/* const reports = ref([
  { period: "12-10-2024", total: "321" },
  { period: "12-10-2024", total: "321" },
  { period: "12-10-2024", total: "321" },
]); */
const formatDateDisplay = (dateString) => {
  // Remove markdown asterisks
  const cleanDateString = dateString.replace(/\*/g, "");

  // Create a Date object
  const date = new Date(cleanDateString);

  // Array of month names in Spanish (abbreviated)
  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  // Format the date
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

onMounted(async () => {
  await reportsStore.getReports();
  isLoading.value = true;
  const token = await getAccessTokenSilently({
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  });
  localStorage.setItem("token", token);
  isLoading.value = false;
});
</script>

<template>
  {{ console.log(reportsStore.reports) }}
  <section>
    <h2 class="">Reportes</h2>
    <table class="w-full">
      <thead>
        <tr class="reporte-header">
          <th class="column">Período Semanal</th>
          <th class="column">Total</th>
          <th class="column">{{ " " }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(report, index) in reportsStore.reports"
          :key="index"
          :class="[
            index % 2 === 0 ? 'reporte-item' : 'reporte-it',
            'reporte-row',
          ]"
        >
          <td class="column">
            {{ `${formatDateDisplay(report.date_from)} - ${formatDateDisplay(report.date_to)}` }}
          </td>
          <td class="column">{{ report.total ? report.total : " ---" }}</td>
          <td class="column">
            <a class="search-button" :href="report.file" download>
              <i class="fa fa-download" aria-hidden="true"></i>
              Descargar reporte
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<style scoped>
a{
  text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}
a:visited{
  text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}
tbody td {
  font-size: 16px;
  font-weight: 500;
}
.column {
  min-width: 208px;
}
.reporte-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  margin-bottom: 8px;
}
.reporte-it {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  background-color: #ececec;
  margin-bottom: 8px;
}
.reporte-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px 10px;
  border-bottom: 1px solid rgb(160, 160, 160);
}
.reporte-header th {
  font-size: 20px;
  font-weight: 500;
}
.reporte-header .column {
  min-width: 225px;
}
.reporte-row {
  display: flex;
  justify-content: space-between;
}
.page-link {
  color: #000;
}
.page-item.active .page-link {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.modal-footer {
  border: none;
}
.cerrar-btn {
  background-color: white;
  color: black;
}
.cerrar-btn:hover {
  background-color: rgb(212, 212, 212);
  color: black;
}
.search-button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  background-color: #212429;
  color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}
.search-but {
  color: #fff;
  background-color: #fff;
  border: none;
  padding-right: 24px;
  padding-left: 80px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 6px;
}
</style>
