<script setup>
import DataTable from "@/components/common/DataTable.vue"
import TextField from "@/components/common/TextField.vue"
import ModalSales from "@/components/ModalSales.vue"
import { useSalesStore } from "@/stores/Sales"
import { computed, onMounted, ref, watch } from "vue"

const salesStore = useSalesStore();
const currentPage = ref(1)
const showModalDetail = ref(false);
const data = ref([
  {
    product: "Product 1",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
  {
    product: "Product 2",
    name: "Hugo perez",
    email: "hugo@hugo",
    phone: "123456789",
    date: "2022-01-01",
  },
]);
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Producto",
  },
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },

  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-4/12",
    title: "Fecha",
  },
  {
    width: "w-4/12",
    title: "Estado",
  },
  {
    width: "w-4/12",
    title: "",
  },
]);

onMounted(async () => {
  await salesStore.getSales();
  await salesStore.getStatus();
});

const changePage = async (page) => {
  if (page < 1 || page > salesStore.pageCount) return;
  currentPage.value = page;
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    page,
    state.value
  );
};

const paginationPages = computed(() => {
  const totalPages = salesStore.pageCount;
  const currentPage = salesStore.page;
  const isMobile = window.innerWidth <= 768; // Detects if the screen is mobile-sized
  const maxDisplayPages = isMobile ? 3 : 7; // 5 pages on mobile, 7 on desktop

  if (totalPages <= maxDisplayPages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  let start = Math.max(1, currentPage - Math.floor(maxDisplayPages / 2));
  let end = start + maxDisplayPages - 1;

  if (end > totalPages) {
    end = totalPages;
    start = Math.max(1, end - maxDisplayPages + 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});



function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

const product = ref(null);
const dateFrom = ref(null);
const dateTo = ref(null);
const state = ref(null);

const search = async () => {
  await salesStore.getSales(
    product.value,
    dateFrom.value,
    dateTo.value,
    1,
    state.value
  );
};


watch(salesStore.page, async (page, previous) => {
  if (previous !== 0 && page !== 0) {
    await salesStore.getSales(
      product.value,
      dateFrom.value,
      dateTo.value,
      page,
      state.value
    );
  }
});

const modalDetail = async (id) => {
  await salesStore.getSale(id);
  showModalDetail.value = true;
};

const formattedNumber = computed(() => {
  const numStr = salesStore.total.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedInteger + (decimalPart ? "." + decimalPart : "");
});

// Helper function to format numbers with two decimals
const formatToTwoDecimals = (value) => {
  if (value === null || value === undefined) return "0.00";

  const number = parseFloat(value.replace(/,/g, ''));
  const roundedNumber = Math.round(number * 100) / 100;
  return roundedNumber.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); 
};

const isProducer = process.env.VUE_APP_APP_TYPE === "skillper";
</script>

<template>
  <section>
    <div class="mb-20">
      <p class="title-text">Monto recaudado</p>
      <div class="row gap-5 ms-1 flex-column-reverse flex-md-row">
        <div v-if="isProducer" class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fs-5 fw-bold">$ {{ formatToTwoDecimals(formattedNumber) }}</p>
          <p class="pb-0 mb-0 fw-medium">Total</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">$ {{ formatToTwoDecimals(salesStore.totalAmountProducer?.toFixed(2)) }}</p>
          <p class="pb-0 mb-0 fw-medium">Por retirar</p>
        </div>
        <div class="col-md-2 rounded p-3 border rounded-lg">
          <p class="fw-bold fs-5">$ {{ formatToTwoDecimals(salesStore.sales?.total_amount_refunded.toFixed(2)) }}</p>
          <p class="pb-0 mb-0 fw-medium">Reembolsado</p>
        </div>
      </div>
    </div>

    <section class="mb-4">
      <div class="main-title">
        <h2 class="fw-semibold">{{ $t("MENU.SALES") }}</h2>
      </div>
      <div class="d-flex w-100 gap-4 flex-column">
        <div class="row w-100">
          <div class="col-12 col-md-2">
            <TextField
              v-model="product"
              id="search"
              type="text"
              name="productPrice"
              :label="'Producto'"
              placeholder="Buscar "
            />
          </div>
          <div class="col-12 col-md-2">
            <TextField
              v-model="dateFrom"
              id="dateFrom"
              type="date"
              name="dateFrom"
              label="Desde"
              placeholder="From "
            />
          </div>
          <div class="col-12 col-md-2">
            <TextField
              v-model="dateTo"
              id="dateTo"
              type="date"
              name="dateTo"
              label="Hasta"
              placeholder="To "
            />
          </div>
          <div class="col-12 col-md-3">
            <div class="d-flex gap-4 justify-content-between items-center">
              <div class="w-100">
                <p v-text="'Estado'" class="mb-0" />
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="state"
                >
                  <option value="" selected>Seleccionar</option>
                  <option
                    v-for="(status, index) in salesStore.statusSales"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.value }}
                  </option>
                </select>
              </div>
              <div class="w-30">
                <button
                  @click="search()"
                  class="btn bg-green mt-4 w-100 text-white"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="table-responsive">
      <DataTable :noHaveData="data?.length === 0" :headers="titlesTable">
        <template #body>
          <tr v-for="(item, index) in salesStore.sales?.results" :key="index">
            <td class="p-3" v-text="item.product.title || '-'" />
            <td class="p-3" v-text="(item.user.username) || '-'"></td>
            <td class="p-3" v-text="item.user.email || '-'" />
            <td class="p-3" v-text="formatDate(item.created_at) || '-'" />
            <td class="p-3" v-text="item.status?.value || '-'" />
            <td class="p-3">
              <p
                class="color-green pb-0 mb-0 underline"
                style="cursor: pointer"
                @click="modalDetail(item.id)"
              >
                Ver detalle
              </p>
            </td>
          </tr>
        </template>
      </DataTable>
    </div>
    <nav class="flex justify-center">
      <ul class="index-nav flex items-left">
        <!-- Previous page button -->
        <li>
          <button
            :class="{
              'cursor-not-allowed opacity-50': salesStore.page === 1,
            }"
            :disabled="salesStore.page === 1"
            @click.prevent="changePage(salesStore.page - 1)"
          >
            Anterior
          </button>
        </li>

        <!-- First page with ellipsis -->
        <li v-if="salesStore.page >= 5">
          <button
            @click.prevent="changePage(1)"
          >
            1...
          </button>
        </li>

        <!-- Dynamic page buttons -->
        <li v-for="page in paginationPages" :key="page">
          <button
            :disabled="salesStore.page === page"
            :class="{
              'font-bold': salesStore.page === page,
              'bg-green text-white': salesStore.page === page
            }"
            @click.prevent="changePage(page)"
          >
            {{ page }}
          </button>
        </li>

        <!-- Last page with ellipsis -->
        <li v-if="salesStore.page <= salesStore.pageCount-4">
          <button
            @click.prevent="changePage(salesStore.pageCount)"
          >
            ...{{ salesStore.pageCount }}
          </button>
        </li>

        <!-- Next page button -->
        <li>
          <button
            :class="{
              'cursor-not-allowed opacity-50': salesStore.page === salesStore.pageCount,
            }"
            :disabled="salesStore.page === salesStore.pageCount"
            @click.prevent="changePage(salesStore.page + 1)"
          >
            Siguiente
          </button>
        </li>
      </ul>
    </nav>
  </section>
  <Teleport to="body">
    <ModalSales
      @close="showModalDetail = false"
      v-if="showModalDetail"
      :sales="salesStore.sale"
    />
  </Teleport>
</template>
