import { defineStore } from "pinia";
import axios from 'axios';

export const useSubscriptionsStore = defineStore("subscriptions", {
  state: () => ({
    _status: null,
    _error: null,
    _subscriptions: [],
    _count: 0,
    _next: null,
    _previous: null,
    _currentPage: 1,
    _pageSize: 10,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    subscriptions: (state) => state._subscriptions.map(sub => ({
      id: sub.id,
      firstName: sub.user.first_name,
      lastName: sub.user.last_name,
      total: sub.total ? sub.total : "-",
      payment: {
        next: sub.next_payment_date,
        last: sub.last_payment_date
      },
      status: sub.state,
      cardNumber: sub.card.vivid_info.number
    })),
    count: (state) => state._count,
    currentPage: (state) => state._currentPage,
    pageCount: (state) => {
      const pageCount = Math.ceil(state._count / state._pageSize);
      return pageCount;
    },
    
    hasNextPage: (state) => !!state._next,
    hasPreviousPage: (state) => !!state._previous,
  },
  actions: {
    changeStatus(status, error = null) {
      this._status = status;
      if (status === "error") this._error = error;
    },
    async getSubscriptions({ productId = null, first_name = '', lastName = '', status = '', page = 1 }) {
      if (!productId) {
        this._subscriptions = [];
        this._count = 0;
        this._next = null;
        this._previous = null;
        this._currentPage = 1;
        this.changeStatus("ready");
        return;
      }

      this.changeStatus("loading");
      
      const url = `${process.env.VUE_APP_API_URL}/api/subscriptions/`;
    
      try {
        const params = {
          page: page,
          product: productId,
          ...(first_name ? { first_name: first_name } : {}),
          ...(lastName ? { last_name: lastName } : {}),
          ...(status ? { status: status } : {}),
        };
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: params,
        });
    
        if (response.status === 200) {
          this._subscriptions = response.data.results;
          this._count = response.data.count;
          this._next = response.data.next;
          this._previous = response.data.previous;
          this._currentPage = page;
        }
    
        this.changeStatus("ready");
      } catch (error) {
        this.changeStatus("error", error.response ? error.response.data : error.message);
        console.error("Error fetching subscriptions:", error.response ? error.response.data : error.message);
      }
    },
    async cancelSubscription(subId) {
      this.changeStatus("loading");
      
      const url = `${process.env.VUE_APP_API_URL}/api/cancel-subscription/`;
      
      try {
        const response = await axios.post(
          url,
          { sub_id: subId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
    
        if (response.status === 200) {
          // Optionally refresh the subscriptions list after cancellation
          await this.getSubscriptions({ 
            productId: this._subscriptions[0]?.product?.id,
            page: this._currentPage 
          });
        }
    
        this.changeStatus("ready");
        return response.data;
      } catch (error) {
        this.changeStatus(
          "error", 
          error.response ? error.response.data : error.message
        );
        console.error(
          "Error canceling subscription:", 
          error.response ? error.response.data : error.message
        );
        throw error;
      }
    },
    setPage(page) {
      this._currentPage = page;
    },
  },
});