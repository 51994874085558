<script setup>
import DataTable from "@/components/common/DataTable.vue"
import EditableRow from "@/components/common/EditableRow.vue"
import { showNotification } from "@/composables/useNotification"
import { useAuth0 } from "@auth0/auth0-vue"
import axios from "axios"
import { BFormSelect, BPagination } from "bootstrap-vue-next"
import { computed, onMounted, ref, watch } from "vue"
import { useRoute } from "vue-router"
import { useProductStore } from "@/stores/productStore"
import router from "@/router"

const { getAccessTokenSilently } = useAuth0();

const route = useRoute();
const { user } = useAuth0();
const productStore = useProductStore();
const product = ref()


onMounted(async () => {
  const token = await getAccessTokenSilently({
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  });
  localStorage.setItem("token", token);
  await productStore.getAllProducts(user.value.sub);

  if (route.params.id) {
    getAffiliates(route.params.id);
    product.value = route.params.id
  }
});

const getProductAffiliates = async (_id) => {
  router.push({ name: "affiliates", params: { id: _id} });
  await getAffiliates(_id);
};

const getAffiliates = async (productId, pageNumber = 1) => {
  try {
    loading.value = true;
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/?product=${productId}&page=${pageNumber}&request_type=producer`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    page.value = pageNumber;
    count.value = response.data.count;
    data.value = response.data.results;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

const updateAffiliateProduct = async (affiliateProduct, payload) => {
  try {
    await axios.patch(
      `${process.env.VUE_APP_API_URL}/api/affiliate-products/${affiliateProduct.id}/?request_type=producer`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    showNotification("Actualizado correctamente", "success");
  } catch (error) {
    console.log(error);
  }
};

const data = ref([]);
const loading = ref(false);
const titlesTable = computed(() => [
  {
    width: "w-4/12",
    title: "Nombre y apellido",
  },

  {
    width: "w-1/6",
    title: "Email",
  },
  {
    width: "w-1/2",
    title: "Teléfono",
  },
  {
    width: "w-4/12",
    title: "Comisión de producto",
  },
  {
    width: "w-4/12",
    title: "Estatus",
  },
]);
const count = ref(0);
const page = ref(0);

const pages = computed(() => {
  return Math.ceil(count.value / 10);
});

const status_choices = [
  { value: "pendiente", text: "Pendiente" },
  { value: "eliminado", text: "Eliminado" },
  { value: "confirmado", text: "Confirmado" },
];

watch(page, async (page, previous) => {
  if (previous !== 0 && page !== 0) {
    await getAffiliates(route.params.id, page);
  }
});

const updateItem = async (item) => {
  const index = data.value.findIndex((obj) => obj.id === item.id);
  data.value[index] = item;
  await updateAffiliateProduct(item, { percentage: item.percentage });
};
</script>
<template>
  <section>
    <div class="main-title">
      <h2>{{ $t("PRODUCT.AFFILIATES") }}</h2>
    </div>
    <div class="">
      <p v-text="'Productos'" class="mb-0" />
      <select
        class="form-select"
        aria-label="Default select example"
        v-model="product"
        @change="getProductAffiliates(product)"
      >
        <option value="" selected>Seleccionar</option>
        <option
          v-for="(obj, index) in productStore.listProducts.products"
          :key="index"
          :value="obj?.id"
        >
          {{ obj?.title }}
        </option>
      </select>
    </div>
    
    <!-- Wrapper for horizontal scroll on mobile -->
    <div class="table-responsive">
      <DataTable
        :noHaveData="data?.length === 0"
        :headers="titlesTable"
        :isLoading="loading"
      >
        <template #body>
          <tr v-for="(item, index) in data" :key="index">
            <td
              class="p-3"
              v-text="
                item.affiliate?.user?.first_name +
                ' ' +
                item.affiliate?.user?.last_name
              "
            />
            <td class="p-3" v-text="item.affiliate.user.email || '-'" />
            <td class="p-3" v-text="item.affiliate.user.phone || '-'" />
            <td class="p-3">
              <EditableRow :item="item" @update-item="updateItem" />
            </td>
            <td class="p-3">
              <BFormSelect
                v-model="item.status"
                :options="status_choices"
                @change="updateAffiliateProduct(item, { status: item.status })"
              />
            </td>
          </tr>
        </template>
      </DataTable>
    </div>

    <BPagination
      v-model="page"
      :total-rows="pages"
      :per-page="10"
      first-text="Primero"
      prev-text="Anterior"
      next-text="Siguiente"
      last-text="Último"
    />
  </section>
</template>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>

